<template>
    <div class="about-us">
      <img src="@/assets/images/about-us.jpg" alt="">
  </div>
  </template>
  
  <script>
  
  export default {
    name: 'AboutUs',
    components: {
    }
  }
  </script>
  <style scoped lang='scss'>
  .about-us{
    text-align: center;
    img{
      display: block;
    }
  }
  </style>